<template>
  <div class="pl-14">
    <v-card>
      <v-app-bar color="teal-darken-4" dark prominent src="https://picsum.photos/1920/1080?random">
        <template v-slot:img="{ props }">
          <v-img v-bind="props" gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"></v-img>
        </template>

        <!-- <v-app-bar-title>{{this.enterprise}}<br></v-app-bar-title> -->
        <v-toolbar-title>{{this.enterprise}}</v-toolbar-title>
      </v-app-bar>

      <v-card-text>
        <v-sparkline :value="value" :gradient="gradient" :smooth="radius || false" :padding="padding"
          :line-width="width" :stroke-linecap="lineCap" :gradient-direction="gradientDirection" :fill="fill"
          :type="type" :auto-line-width="autoLineWidth" auto-draw></v-sparkline>
        <v-card class="mx-auto">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <v-row>
                  <v-col cols="2" class="mt-2">
                    Informe ventas
                  </v-col>
                  <v-col cols="2">
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="initialDate" label="Fecha Inicial" prepend-icon="mdi-calendar" readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="initialDate"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="2020-01-01" locale="es-co" @change="save1"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="finalDate" label="Fecha Final" prepend-icon="mdi-calendar" readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="finalDate"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="2020-01-01" locale="es-co" @change="save2"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols=1>
                    <v-btn color="primary" dark class="mt-2" @click="search_data">
                      <v-icon>search</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols=3 v-if="isAdmin">
                    <v-autocomplete v-model="report" :items="itemsExcel" persistent-hint hide-no-data item-text="text"
                      label="Seleccione informe" class="mx-4" clearable return-object>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="isAdmin">
                    <v-btn color="success" dark class="mt-2" @click="search_report">
                      <v-icon>mdi-file-excel-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item v-for="(item, i) in itemsReport" :key="i" :color="item.color" :icon="item.icon" fill-dot>
            <v-card :color="item.color" dark>
              <v-card-title class="text-h6">
                {{item.text}}
              </v-card-title>
              <v-card-text class="white text--primary">
                <v-chip v-for="(itemValue, iv) in item.value" :key="iv" class="ma-2" :color="item.color" large>
                  {{itemValue}}
                </v-chip>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import Inventario from "./inventory/inventory"
import { ApiPay  } from "./billing/ApiPay";
import { ApiInv  } from "./inventory/ApiInv";

const gradients = [
    ['#222'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
  ]
export default {
    name: "Principal",
    components:{
      // Inventario
    },
    data(){
      return { 
        width: 2,
        radius: 10,
        padding: 8,
        lineCap: 'round',
        gradient: gradients[5],
        value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
        gradientDirection: 'top',
        gradients,
        fill: false,
        type: 'trend',
        autoLineWidth: false,
        enterprise:"",
        menu1: false,
        menu2: false,
        api: new ApiPay(),
        apiInv: new ApiInv(),
        report:{
            text: 'Clientes',
            value: 'clients',
            url: `enterprise=${localStorage.getItem("Sucursal")}`
          },
        itemsExcel:[
          {
            text: 'Clientes',
            value: 'clients',
          },
          {
            text: 'Facturas',
            value: 'bills',
          },
          {
            text: 'Inventario',
            value: 'inventory',
          },
        ],
        items: [
          {
            color: '#6A76AB',
            icon: 'mdi-alpha-v-box',
            text:'Total Ventas',
            api_name: 'total_sales',
            value:''
          },
          {
            color: 'purple lighten-1',
            icon: 'mdi-alpha-f-box',
            text:'Facturas generadas',
            api_name: 'bills_quantity',
            value:''
          },
          {
            color: 'green lighten-1',
            icon: 'mdi-alpha-p-box',
            text:'Productos Vendidos',
            api_name: 'products',
            value:''
          },
          {
            color: 'red lighten-2',
            icon: 'mdi-alpha-s-box',
            text:'Servicios Prestados',
            api_name: 'services',
            value:''
          },
          {
            color: 'blue lighten-1',
            icon: 'mdi-alpha-c-box',
            text:'Comisión Sucursal',
            api_name: 'commission',
            value:''
          },
          {
            color: 'blue lighten-1',
            icon: 'mdi-alpha-n-box',
            text:'Nomina',
            api_name: 'payroll',
            value:''
          },
        ],
        initialDate:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        finalDate:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      }
    },
    computed: {
      itemsReport() {
        return this.items;
      },
      isAdmin() {
        const groups = localStorage.getItem("groups")
        return groups.includes("view_adminenterprise") ? true : false
      },
    },
    methods:{
      selectEnterprise(branch){
        this.enterprise = branch
      },
      async search_data(){
        let params = `enterprise=${localStorage.getItem("SucursalId")}&i_date=${this.initialDate}&f_date=${this.finalDate}`
        let r = await this.api.getFacturasInf(params);
        for (const item in this.items){
          this.items[item]["value"] = r[this.items[item]["api_name"]] 
        }
      },
      save1 (date) {
        this.$refs.menu1.save(date)
      },
      save2 (date) {
        this.$refs.menu2.save(date)
      },
      async search_report(){
        let params = `enterprise=${localStorage.getItem("Sucursal")}`
        switch (this.report.value) {
          case 'clients':
            await this.api.getExcelClientes(params);
            break;
          case 'bills':
            await this.api.getExcelFact(`enterprise=${localStorage.getItem("Sucursal")}&i_date=${this.initialDate}&f_date=${this.finalDate}`);
            break;
          case 'inventory':
            await this.apiInv.getExcelInv(params);
            break;
        }
      },
    },
    created(){
      this.enterprise = localStorage.getItem("Sucursal");
      this.search_data();
    },
}

</script>

<style>

.color-fondo {
    background-color: #FFFBE9 !important;
}
.color-texto {
    color: #356859 !important;
}

</style>