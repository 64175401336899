var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-14"},[_c('v-card',[_c('v-app-bar',{attrs:{"color":"teal-darken-4","dark":"","prominent":"","src":"https://picsum.photos/1920/1080?random"},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"}},'v-img',props,false))]}}])},[_c('v-toolbar-title',[_vm._v(_vm._s(this.enterprise))])],1),_c('v-card-text',[_c('v-sparkline',{attrs:{"value":_vm.value,"gradient":_vm.gradient,"smooth":_vm.radius || false,"padding":_vm.padding,"line-width":_vm.width,"stroke-linecap":_vm.lineCap,"gradient-direction":_vm.gradientDirection,"fill":_vm.fill,"type":_vm.type,"auto-line-width":_vm.autoLineWidth,"auto-draw":""}}),_c('v-card',{staticClass:"mx-auto"},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"2"}},[_vm._v(" Informe ventas ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicial","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"2020-01-01","locale":"es-co"},on:{"change":_vm.save1},model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.finalDate),callback:function ($$v) {_vm.finalDate=$$v},expression:"finalDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),"min":"2020-01-01","locale":"es-co"},on:{"change":_vm.save2},model:{value:(_vm.finalDate),callback:function ($$v) {_vm.finalDate=$$v},expression:"finalDate"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.search_data}},[_c('v-icon',[_vm._v("search")])],1)],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"mx-4",attrs:{"items":_vm.itemsExcel,"persistent-hint":"","hide-no-data":"","item-text":"text","label":"Seleccione informe","clearable":"","return-object":""},model:{value:(_vm.report),callback:function ($$v) {_vm.report=$$v},expression:"report"}})],1):_vm._e(),(_vm.isAdmin)?_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"success","dark":""},on:{"click":_vm.search_report}},[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1)],1):_vm._e()],1)],1)],1)],1)],1),_c('v-timeline',{attrs:{"align-top":"","dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((_vm.itemsReport),function(item,i){return _c('v-timeline-item',{key:i,attrs:{"color":item.color,"icon":item.icon,"fill-dot":""}},[_c('v-card',{attrs:{"color":item.color,"dark":""}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-card-text',{staticClass:"white text--primary"},_vm._l((item.value),function(itemValue,iv){return _c('v-chip',{key:iv,staticClass:"ma-2",attrs:{"color":item.color,"large":""}},[_vm._v(" "+_vm._s(itemValue)+" ")])}),1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }